:root {
  --swiper-navigation-size: 30px;
  --swiper-theme-color: black;
  --swiper-gutters-x: 26px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
}

.swiper {
  position: relative;
  padding: 0 var(--swiper-gutters-x);
}

.swiper-slide {
  height: auto;
}

.swiper-slide > .product-card {
  padding-top: 0;
  padding-bottom: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.thumbnails-carousel .swiper-button-prev,
.thumbnails-carousel .swiper-button-next {
  width: var(--swiper-gutters-x);
  height: 100%;
  top: 0;
  margin-top: 0;
  background-color: white;
}

.swiper.swiper-horizontal .swiper-pagination {
  bottom: 16px;
}

.swiper-pagination .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.75);
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: white;
}
